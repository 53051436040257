/// <reference types="grecaptcha" />

export const useRecaptcha = () => {
    const config = useRuntimeConfig();
    const token = ref('');

    function renderRecaptchaScript() {
        const sc = document.getElementById('recaptcha-script');

        if (!sc) {
            let script = document.createElement('script');

            script.id = 'recaptcha-script';
            script.type = 'text/javascript';
            script.async = true;
            script.defer = true;
            script.src = `https://www.google.com/recaptcha/api.js?render=${config.public.recaptchaKey}`;

            document.head.appendChild(script);
        }
    }

    function destroyRecaptchaScript() {
        let script = document.getElementById('recaptcha-script');

        if (script)
            script.remove();
    }

    async function executeRecaptcha() {
        token.value = await grecaptcha.execute(config.public.recaptchaKey, { action: 'submit' });
    }

    return {
        executeRecaptcha,
        renderRecaptchaScript,
        destroyRecaptchaScript,
        token
    };
};